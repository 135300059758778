// 不同的地址对应不同的的访问路径
const http_ = window.location.protocol + '//';
// const URL = 'ads.ketangbang.com/hnjxjy/'; // 测试
const URL = 'ads.jkten.com/hnjxjy/'; // 线上
const BASE_URL = http_ + URL;
module.exports = {
    BASE_URL,
    BASE_URL_HTTP: `http://${URL}`,
    BASE_URL_2: http_ + ''
}
