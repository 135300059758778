<style  lang="less">
.item .el-form-item__label{
  color: #64666A;
  font-weight: bold;
  text-align: left;
  margin-left: 50px;
}
.t_audit_work {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>
<template>
  <div class="t_audit_work">
    <div class="stu-module-header">
      <div class="stu-module-title">审核大作业</div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="姓名/学号">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="毕业批次">
          <el-select v-model="form.batchId">
            <el-option
              v-for="(item, index) in batchOptions"
              :key="index"
              :label="item.batchName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="form.auditState">
            <el-option
              v-for="(item, index) in auditStateOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="init" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="height"
        stripe
      >
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="studentName" align="center"></el-table-column>
        <el-table-column label="学号" prop="studentNo" align="center"></el-table-column>
        <el-table-column label="专业" prop="profName" align="center"></el-table-column>
        <el-table-column label="联系电话" prop="contactPhone" align="center"></el-table-column>
        <el-table-column label="提交时间" prop="createTime" align="center"></el-table-column>
        <el-table-column label="审核状态" prop="paperStatus" align="center"><template slot-scope="scope">
          <span v-if="scope.row.paperStatus==1">等待审核</span>
          <span v-else-if="scope.row.paperStatus==2">审核通过</span>
          <span v-else-if="scope.row.paperStatus==3">驳回</span>
        </template></el-table-column>
        <el-table-column label="审核" prop="" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="audit(scope.row)">
              查看审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="this.form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>

      <!-- 添加 -->
      <el-dialog
          title="审核"
          :visible.sync="dialogVisible"
          width="50%"
          style="text-align: left"
          @close="init()"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm item"
            label-width="120px"

        >

          <el-row>
            <el-col :span="12">
              <el-form-item  label="姓名："  prop="jobNo">
               <div>{{ bo.studentName }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="学号："  prop="jobNo">
                <div>{{ bo.studentNo }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="专业："  prop="jobNo">
                <div>{{ bo.profName }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="联系方式："  prop="jobNo">
                <div>{{ bo.contactPhone }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="提交时间："  prop="jobNo">
                <div>{{ bo.createTime }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核时间："  prop="jobNo">
                <div>{{ bo.examTime }}</div>
              </el-form-item>
            </el-col>
            <el-row>
            <el-col :span="12">
              <el-form-item  label="毕业大作业："  prop="jobNo">
                <el-button @click="downloads" type="text">下载</el-button>
              </el-form-item>
            </el-col>
            </el-row>
            <el-col :span="12">
              <el-form-item  label="审核状态："  prop="jobNo">
                <el-select style="width: 200px" v-model="bo.paperStatus">
                  <el-option
                      v-for="(item, index) in auditStateOptionss"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核附件："  prop="jobNo">
                <div>
                <el-button @click="download" type="text">下载</el-button>
                </div>
                <el-upload
                    action="#"
                    :auto-upload="false"
                    :http-request="uploadS"
                    :on-change="uploadChange"
                >
                  <el-button size="small" style="margin-left: 50px" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-show="bo.paperStatus==2"  label="成绩："  prop="jobNo">
                <el-input
                    v-model="bo.score"
                    placeholder="请输入内容"
                    style="width: 200px;height: 100px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核评语："  prop="jobNo">
                <el-input
                    v-model="bo.stuffInfo"
                    placeholder="请输入内容"
                    style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-button style="margin-bottom: 40px;margin-left: 500px" @click="edit" type="primary">审核</el-button>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "../../../../libs/domain";

export default {
  name: "t_audit_work",
  components: {},
  data() {
    return {
      height: window.innerHeight - 380,
      form: {
        name: "",
        batchId: "",
        page: 1,
        pageSize: 10,
        auditState: "",
      },
      batchOptions: [],
      dialogVisible:false,
      shows:false,
      bo: {
        id:0,
        studentName:"",
        attachmentUrl:"",
        stuffUrl:"",
        studentNo:"",
        profName:"",
        contactPhone:"",
        createTime:"",
        examTime:"",
        stuffInfo:null,
        paperStatus:"",
        paperStatuss:"",
        checkStatus:"",
        paperStatues:"",
        score:"",
        file:[]
      },
      auditStateOptions: [
        { label: "等待审核", value: 1 },
        { label: "审核通过", value: 2 },
        { label: "审核驳回", value: 3 },
      ],
      auditStateOptionss: [
        { label: "等待审核", value: 1 },
        { label: "审核通过", value: 2 },
        { label: "审核驳回", value: 3 },
      ],
      column: [
        { label: "姓名", prop: "studentName" },
        { label: "学号", prop: "studentNo" },
        { label: "专业", prop: "profName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "提交时间", prop: "createTime" },
        { label: "审核状态", prop: "paperStatus" },
      ],
      tableData: [],
      total: 0,
      pageSize: 20,
      current: 1,
    };
  },
  mounted() {
    this.init();
    this.QueryEduGraduateWorkBatch();
  },
  methods: {
    init() {
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/teacherCourse/queryExamineEduCourseGraduateWorkByTea?page="+this.form.page+"&pageSize="+this.form.pageSize
          +"&batchId="+this.form.batchId
          +"&studentNameOrNum="+this.form.name
          +"&checkStatus="+this.form.auditState
      ).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
          this.loadingView.close()
        }else if (resp.data.code==5002){
          this.loadingView.close()
          this.tableData = [];
        }
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.get("/hnjxjy-core/teacherCourse/queryEduGraduateWorkBatch").then(resp => {
        if (resp.data.code == 2000) {
          this.batchOptions = resp.data.data;
        }
      })
    },
    download(){
      if(this.bo.stuffUrl!=null&&this.bo.stuffUrl!=""){
        window.open(this.bo.stuffUrl)
      }else {
        this.$message({
          type:"error",
          message:"附件为空"
        })
      }
    },
    downloads(){
      if(this.bo.attachmentUrl!=null&&this.bo.attachmentUrl!=""){
        window.open(this.bo.attachmentUrl)
      }else {
        this.$message({
          type:"error",
          message:"附件为空"
        })
      }
    },
    uploadChange(file){
      this.bo.file = file.raw
    },
    uploadS(){

    },
    uploadFiles(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/upload/uploadPictures",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            this.teacherForm.url = res.data.data
          })
          .catch((err) => {
          });
    },

    query() {},
    audit(row) {
      if(row.paperStatus!=1){
        this.$message.info("该学生已经审核")
      }else{
            let linkRow = JSON.parse(JSON.stringify(row));
            this.bo = linkRow
            this.bo.studentName = linkRow.studentName
            this.bo.studentNo = linkRow.studentNo
            this.bo.profName = linkRow.profName
            this.bo.contactPhone = linkRow.contactPhone
            this.bo.createTime = linkRow.createTime
            this.bo.examTime = linkRow.examTime
            this.bo.attachmentUrl = linkRow.attachmentUrl
            this.bo.stuffUrl = linkRow.stuffUrl
        this.dialogVisible = true
        this.bo.id = linkRow.id
      this.bo.paperStatues = linkRow.paperStatus
     }

    },
    edit(){
      let fromData = new FormData();
      if(this.bo.file!=null&&this.bo.file!=""){
        fromData.append("file",this.bo.file);
      }else{
        return this.$message.error("审核附件不能为空")
      }
      if(this.bo.stuffInfo!=null&&this.bo.stuffInfo!=""){
        fromData.append("stuffInfo",this.bo.stuffInfo);
      }
      if (null != this.bo.score && "" !== this.bo.score){
        fromData.append("score",this.bo.score);
      }
      fromData.append("checkStatus",this.bo.paperStatus);
      fromData.append("id",this.bo.id);
      if(this.bo.paperStatues!=1){
        this.$message.info("该学生已经审核")
      }else{
        this.openLoadingView();
        this.$axios_supermallData.post("hnjxjy-core/teacherCourse/examineEduCourseGraduateWorkByTea",fromData).then(resp => {
          if (resp.data.code == 2000){
            this.$message.success("操作完成");
            this.init();
            this.dialogVisible = false;
          }else {
            this.$message.warning(resp.data.message);
            this.loadingView.close();
          }
        })
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>
